import React from 'react';
import { Suspense } from 'react';
import {hydrate, render} from 'react-dom';
import './index.css';
const App = React.lazy(() => import('./App'))


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Suspense fallback={<div className='loader'>Loading...</div>}>
         <App />
       </Suspense>, rootElement);
} else {
  render(<Suspense fallback={<div className='loader'>Loading...</div>}>
         <App />
       </Suspense>, rootElement);
}

// ReactDOM.render(
//   <React.StrictMode>
//     <Suspense fallback={<div className='loader'>Loading...</div>}>
//       <App />
//     </Suspense>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
